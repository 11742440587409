// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBPjkLJiR-nb1M-glq5zvXuGNRjXanPvdU",
  authDomain: "trading-system-b4423.firebaseapp.com",
  projectId: "trading-system-b4423",
  storageBucket: "trading-system-b4423.appspot.com",
  messagingSenderId: "891260490316",
  appId: "1:891260490316:web:27241ffb3676f1534a1ae9",
  measurementId: "G-F2D7P5P93G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize the Firebase components
const auth = getAuth();
// const analytics = getAnalytics(app);
const db = getFirestore();

export {
  auth,
  // analytics,
  db,
  logEvent
}