import React, { useEffect } from 'react';

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import HeroHome from '../partials/HeroHome';
import Process from '../partials/Process';
import FeaturesHome from '../partials/FeaturesHome';
import Tabs from '../partials/Tabs';
import Target from '../partials/Target';
import News from '../partials/News';
import Newsletter from '../partials/Newsletter';
import Footer from '../partials/Footer';
// #151719 #111827
function Home() {
  useEffect(() => {
    // console.log("Rewardful UUID", )
    // get rewardful reference id from window
    console.log("Rewardful", window.Rewardful.referral);
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden" style={{ backgroundColor: "#151719" }}>

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">

        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <HeroHome />
        <Process />
        {/* <FeaturesHome /> */}
        <Tabs />
        {/* <Target /> */}
        <News />
        <Newsletter />

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Home;