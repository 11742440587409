const SOCKET_URL = 'wss://signal.wavealgo.com';

// production websocket server => wss://signal.wavealgo.com
// debug websocket server => ws://127.0.0.1:80

export default class SignalSocketClient {
  constructor(isTrial) {
    this.isTrial = isTrial;
    this.socket = new WebSocket(SOCKET_URL);
    this.socket.onopen = this._onOpen.bind(this);
    this.socket.onmessage = this._onMessage.bind(this);
    this.socket.onclose = this._onClose.bind(this);
    this.socket.onerror = this._onError.bind(this);
    this.signalListener = function () { }
    this.eventListener = function () { }
  }

  setSignalCallback(fCallback) {
    this.signalListener = fCallback;
  }

  setEventCallback(fCallback) {
    this.eventListener = fCallback;
  }

  _onOpen(e) {
    console.info(`Signal WS Open`);
    if (this.isTrial) {
      this.socket.send(JSON.stringify({
        type: 'sessionPermission',
        state: 'trial'
      }));
    } else {
      this.socket.send(JSON.stringify({
        type: 'sessionPermission',
        state: 'production'
      }));
    }
  }

  _onMessage(e) {
    const data = JSON.parse(e.data);

    if (data instanceof Array) {
      this.signalListener(data);
    } else {
      if (Object.keys(data).includes('type')) {
        if (data.type === 'connections') {
          this.eventListener(data);
        } else {
        }
      }
    }

    // try {
    //   let d = JSON.parse(e.data)
    //   this.signalListener(d);
    // } catch (err) {
    //   return;
    // }
  }

  _onClose(e) {
  }

  _onError(e) {
    console.log('onError', e);
  }
}