import { useEffect } from "react"

import { getApp } from "@firebase/app";
import { getStripePayments, getProducts, createCheckoutSession, getCurrentUserSubscriptions, onCurrentUserSubscriptionUpdate } from "@stripe/firestore-stripe-payments";


export default function Test() {
  useEffect(async () => {
    console.log("Page Test Load!")

    const app = getApp();
    const payments = getStripePayments(app, {
      productsCollection: "products",
      customersCollection: "customers",
    });

    console.log("PAYMENTS", payments);
    const prods = await getProducts(payments, {
      includePrices: true,
      activeOnly: true
    });
    console.log("PRODS", prods);

    return () => null;
  }, []);

  return (
    <div>

    </div>
  )
}