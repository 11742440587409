import React, { Component } from "react";
import binanceAPI from "../services/api";
import SignalSocketClient from "../services/signalSocketClient";
import { trialPing } from "../services/httpApi";

export default class TradingChart extends Component {
  constructor({ chartProperties, onRealtimeConnectionChange }) {
    super();
    this.state = {
      customeResolution: null
    };
    this.bfAPI = new binanceAPI({ debug: false });
    this.widgetOptions = {
      container_id: "chart_container",
      datafeed: this.bfAPI,
      library_path: "/charting_library/",
      disabled_features: [],
      indicators_file_name: "/charting_library/static/ma_indicators.js",
      ...chartProperties,
      overrides: {
        // https://github.com/tradingview/charting_library/issues/5744
        // 차트 타입 설정
        "mainSeriesProperties.style": 1,
        // 차트 패널 백그라운드 색 설정
        "paneProperties.background": "#000000",
        "paneProperties.vertGridProperties.color": "#000000",
        "paneProperties.horzGridProperties.color": "#000000",
        'paneProperties.crossHairProperties.color': '#ffffff',
        "paneProperties.legendProperties.showLegend": true,
        "paneProperties.legendProperties.showStudyArguments": true,
        "paneProperties.legendProperties.showStudyTitles": true,
        "paneProperties.legendProperties.showStudyValues": true,
        "paneProperties.legendProperties.showSeriesTitle": true,
        "paneProperties.legendProperties.showSeriesOHLC": true,
      }
    };
    this.tradingViewWidget = null;
    this.chartObject = null;

    this.signalSocketClient = new SignalSocketClient(chartProperties.isTrial);
    this.signalSocketClient.setEventCallback((data) => {
      if (data.type === "connections") {
        //console.log(data.data.connections);
        onRealtimeConnectionChange(data.data.connections);
      }
    });

    this.signalSocketClient.setSignalCallback((signals) => {
      if (this.tradingViewWidget == null) {
        return;
      }
      // https://github.com/tradingview/charting_library/wiki/Chart-Methods
      // https://github.com/tradingview/charting_library/wiki/Shapes-and-Overrides
      // console.log(signals)

      setTimeout(() => {
        signals.forEach((signal) => {

          // Buy_Signal: buySignal,
          // Sell_Signal: sellSignal,
          // Buy: data.Buy,
          // Sell: data.Sell,
          // Minimal_Buy: data.Minimal_Buy,
          // Minimal_Sell: data.Minimal_Sell,
          // Strong_Buy: data.Strong_Buy,
          // Strong_Sell: data.Strong_Sell,
          // Minimal_Strong_Buy: data.Minimal_Strong_Buy,
          // Minimal_Strong_Sell: data.Minimal_Strong_Sell,

          let text = "";
          if (signal.Buy) {
            text = "Buy";
          }

          if (signal.Sell) {
            text = "Sell";
          }

          if (signal.Minimal_Buy) {
            text = "Minimal_Buy";
          }

          if (signal.Minimal_Sell) {
            text = "Minimal_Sell";
          }

          if (signal.Strong_Buy) {
            text = "Strong_Buy";

          }

          if (signal.Strong_Sell) {

            text = "Strong_Sell";
          }

          if (signal.Minimal_Strong_Buy) {
            text = "Minimal_Strong_Buy";
          }

          if (signal.Minimal_Strong_Sell) {
            text = "Minimal_Strong_Sell";
          }

          if (signal.Buy_Signal == 1) {
            //console.log("Buy Signal");
            this.tradingViewWidget.activeChart().createShape({ time: signal['$time'], price: signal.min, text }, { shape: 'arrow_up', zOrder: 'bottom', text });
          } else if (signal.Sell_Signal == 1) {
            //console.log("Sell Signal");
            this.tradingViewWidget.activeChart().createShape({ time: signal['$time'], price: signal.max, text }, { shape: 'arrow_down', zOrder: 'bottom', text });
          }
        });
      }, 2000);

      setInterval(async () => {
        await trialPing();
      }, 1000 * 60);
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.isSignalWork !== prevProps.isSignalWork) {

    }
  }

  chartReady = () => {
    if (!this.tradingViewWidget) return;

    this.tradingViewWidget.onChartReady(() => {
      // setTimeout(() => {
      //   var button = this.tradingViewWidget.createButton();
      //   console.log(button);
      //   button.innerText = "Custom Button";
      //   button.addEventListener('click', function () { alert("My custom button pressed!"); });
      //   button.textContent = 'My custom button caption';
      //   console.log(button)
      // }, 1000);



      //this.tradingViewWidget.chart().createStudy('Stochastic', false, false, [26], null, { "%d.color": "#FF0000" })
      //this.tradingViewWidget.chart().createStudy('Price Exhaustion', false, false)
      //this.tradingViewWidget.chart().createStudy('ShuBenRSI', false, false)
      //this.tradingViewWidget.chart().createStudy('Wave System', false, false)
      this.tradingViewWidget.chart().createStudy('Wave.line', false, false)
      this.chartObject = this.tradingViewWidget.activeChart();

      //console.log(this.chartObject.priceFormatter().format(0.5))

      //this.tradingViewWidget.activeChart().createShape({ time: 1645707600000 / 1000, price: 35700 }, { shape: 'arrow_down', zOrder: 'top' });

      // setInterval(() => {
      //   // console.log("AA")
      //   // this.tradingViewWidget.activeChart().refreshMarks();
      // }, 1000);
      // ()

      // draw chart to buy signal
      //const item = this.tradingViewWidget.activeChart().createShape({ time: 1660100400000 / 1000, price: 22939 }, { shape: 'arrow_down', zOrder: 'top' });
      //console.log(item);

      // loop data array

      // remove shape
      // this.tradingViewWidget.activeChart().removeShape(item);

      // get all shape names
      //console.log("SHAPE", this.tradingViewWidget.activeChart())
    });
  };

  componentDidMount() {
    this.tradingViewWidget = window.tvWidget = new window.TradingView.widget(
      this.widgetOptions
    );
    this.chartReady();
  }

  componentDidUpdate() {
    if (!this.tradingViewWidget) return;
  }

  handleInputChange = (e) => {
    this.setState({ customeResolution: e.target.value });
  };

  handleKeyDown = (e) => {
    if (e.which === 13) {
      this.chartObject.setResolution(this.state.customeResolution);
    }
  };

  render() {
    return (
      <>
        {/* <input
          onChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          value={this.state.customeResolution}
          placeholder="Input your resolution"
        /> */}

        <div id="chart_container" style={{
          height: '100%',
          backgroundColor: 'black'
          // paddingBottom: 20,
        }}></div>
      </>
    );
  }
}
