import React from 'react';
import ReactDOM from 'react-dom';
// import './css/style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import mixpanel from 'mixpanel-browser';

mixpanel.init('4595d2044317cb89b7f2d1267e68627e', { debug: true });
mixpanel.track('visit');

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();