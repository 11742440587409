import React, { useEffect } from "react";
import TradingChart from "../partials/TradingChart";
import { isTrial } from "../services/httpApi";
import RequirePurchaseModal from "../partials/RequirePurchaseModal";
import { TradingViewEmbed, widgetType } from "react-tradingview-embed";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from 'react-router-dom';
import { getApp } from "@firebase/app";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "@firebase/functions";

const createPortalLink = async (returnUrl) => {
  const app = getApp();
  const functions = getFunctions(app, "us-central1");
  // connectFunctionsEmulator(functions, "localhost", 5001);

  const params = { returnUrl };
  const fn = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

  const response = await fn(params);
  return response.data;
};

const useRoles = () => {
  const [roles, setRoles] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);

  const auth = useAuth();
  React.useEffect(() => {
    (async () => {
      setRoles(await auth.getRoles());
      setIsLoading(false);
    })();
  }, [auth]);

  return {
    roles,
    isLoading,
  };
};

function getLocalLanguage() {
  // #3B82F6
  return navigator.language.split("-")[0] || "en";
}

const Chart = () => {
  const { currentUser, getRoles } = useAuth();
  const { roles, isLoading } = useRoles();
  const navigate = useNavigate();

  const [isSignalTrial, setIsSignalTrial] = React.useState(false);
  const [isSignalWork, setIsSignalWork] = React.useState(false);
  const [isPurchase, setIsPurchase] = React.useState(false);

  const openPortal = React.useCallback(async () => {
    const portal = await createPortalLink(window.location.href);
    window.location.assign(portal.url);
  }, []);

  useEffect(() => {
    if (!currentUser) {
      navigate('/signin')
    }
  }, [currentUser]);

  React.useEffect(() => {

  }, []);

  React.useEffect(() => {
    if (!roles || !roles[0]) {
      setIsPurchase(false);
    } else {
      console.log(roles)
      setIsPurchase(true);
    }
  }, [roles]);

  // const checkTrial = () => {
  //   isTrial().then((response) => {
  //     if (response.isExpired) {
  //       setIsSignalTrial(false);
  //       setIsSignalWork(false);
  //     } else {
  //       setIsSignalTrial(true);
  //       setIsSignalWork(false);
  //     }

  //     setTimeout(() => {
  //       checkTrial();
  //     }, 10000);
  //   });
  // }

  return (
    <div className="h-screen w-screen  bg-gray-800 overflow-hidden flex flex-col">

      {
        !isPurchase && !isLoading ? (
          <RequirePurchaseModal />
        ) : null
      }

      <div>
        <TradingViewEmbed
          widgetType={widgetType.TICKER_TAPE}
          widgetConfig={{
            showSymbolLogo: false,
            isTransparent: false,
            displayMode: "adaptive",
            colorTheme: "dark",
            autosize: false,
            symbols: [
              {
                proName: "BITSTAMP:ETHUSD",
                title: "ETH/USD"
              },
              {
                proName: "BITSTAMP:BTCUSD",
                title: "BTC/USD"
              },
              {
                proName: "BINANCE:BNBUSDT",
                title: "BNB/USDT"
              },
              {
                proName: "BINANCE:ADAUSD",
                title: "ADA/USD"
              },
              {
                proName: "BINANCE:DOTUSDT",
                title: "DOT/USDT"
              },
              {
                proName: "UNISWAP:UNIUSDT",
                title: "UNI/USDT"
              }
            ]
          }}
        />
      </div>

      <div style={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        backgroundColor: "white",
      }}>
        {/* <div className="flex flex-row">
          <img
            src="/assets/imgs/icons/algorithm.png"
            style={{ width: 41, height: 41 }}
          />

          <img
            src="/assets/imgs/icons/logo-fin.png" />
        </div> */}
        {/* <div className="h-screen">
          <TradingChart chartProperties={{
            locale: getLocalLanguage(),
            debug: false,
            fullscreen: false,
            symbol: "BTCUSDT",
            interval: "30",
            theme: "dark",
            allow_symbol_change: true,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            autosize: true,
            disabled_features: ["header_resolutions"],
            isSignalWork: this.state.isSignalWork
          }} />
        </div> */}

        <div style={{ flex: 1 }}>
          <TradingChart chartProperties={{
            locale: getLocalLanguage(),
            debug: false,
            fullscreen: false,
            symbol: "BTCUSDT",
            interval: "15",
            theme: "dark",
            allow_symbol_change: true,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            autosize: true,
            // disabled_features: ["header_resolutions"],
            isSignalWork: true,
            isTrial: false
          }} />
        </div>

        <div className="bg-gray-800">
          <TradingViewEmbed
            widgetType={widgetType.MARKET_OVERVIEW}
            widgetConfig={{
              colorTheme: "dark",
            }} />
        </div>
      </div>
    </div>
  )
}

export default Chart;