import axios from 'axios';

const API_HOST = 'https://us-central1-trading-system-b4423.cloudfunctions.net'

export async function isTrial() {
  const response = await axios.get(`${API_HOST}/isTrial`);
  return response.data;
}

export async function trialPing() {
  const response = await axios.get(`${API_HOST}/trialPing`);
  return response.data;
}