import React from "react";
import TradingChart from "../partials/TradingChart";
import { isTrial } from "../services/httpApi";
import TrialExpiredModal from "../partials/TrialExpiredModal";
import { TradingViewEmbed, widgetType, } from "react-tradingview-embed";
import mixpanel from 'mixpanel-browser';


function getLocalLanguage() {
  // #3B82F6
  return navigator.language.split("-")[0] || "en";
}

export default class ShareChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSignalTrial: false,
      isSignalWork: false,
      isVideoModalVisible: false,
      connections: '',
    }
  }

  componentDidMount() {
    mixpanel.track('PAGE_ShareChart');
    this.checkTrial();
  }

  checkTrial() {
    isTrial().then((response) => {
      if (response.isExpired) {

        //real
        this.setState({
          isSignalTrial: true,
          isSignalWork: false
        });

        //test

        // this.setState({
        //   isSignalTrial: false,
        //   isSignalWork: true
        // })
      } else {

        //real
        this.setState({
          isSignalTrial: false,
          isSignalWork: true
        });

        //test
      }

      setTimeout(() => {
        this.checkTrial();
      }, 10000);
    });
  }

  render() {
    return (
      <div className="h-screen w-screen  bg-gray-800 overflow-hidden flex flex-col">
        {
          this.state.isSignalTrial ? (
            <TrialExpiredModal />
          ) : null
        }

        {/* {
          this.state.isVideoModalVisible ? (
            <VideoModal onModalClose={() => this.setState({ isVideoModalVisible: false })} />
          ) : null
        } */}


        <div>
          <TradingViewEmbed
            widgetType={widgetType.TICKER_TAPE}
            widgetConfig={{
              showSymbolLogo: false,
              isTransparent: false,
              displayMode: "adaptive",
              colorTheme: "dark",
              autosize: false,
              symbols: [
                {
                  proName: "BITSTAMP:ETHUSD",
                  title: "ETH/USD"
                },
                {
                  proName: "BITSTAMP:BTCUSD",
                  title: "BTC/USD"
                },
                {
                  proName: "BINANCE:BNBUSDT",
                  title: "BNB/USDT"
                },
                {
                  proName: "BINANCE:ADAUSD",
                  title: "ADA/USD"
                },
                {
                  proName: "BINANCE:DOTUSDT",
                  title: "DOT/USDT"
                },
                {
                  proName: "UNISWAP:UNIUSDT",
                  title: "UNI/USDT"
                }
              ]
            }}
          />
        </div>

        <div style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          backgroundColor: "white",
        }}>
          {/* <div className="flex flex-row">
            <img
              src="/assets/imgs/icons/algorithm.png"
              style={{ width: 41, height: 41 }}
            />

            <img
              src="/assets/imgs/icons/logo-fin.png" />
          </div> */}
          {/* <div className="h-screen">
            <TradingChart chartProperties={{
              locale: getLocalLanguage(),
              debug: false,
              fullscreen: false,
              symbol: "BTCUSDT",
              interval: "30",
              theme: "dark",
              allow_symbol_change: true,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              autosize: true,
              disabled_features: ["header_resolutions"],
              isSignalWork: this.state.isSignalWork
            }} />
          </div> */}

          <div style={{ flex: 1, flexDirection: 'row' }}>
            {/* <button
              onClick={() => {
                this.setState({
                  isVideoModalVisible: true
                });
              }}
              className="text-white w-full bg-slate-600 mx-auto">
              WAVE.ALGO 소개 동영상 보기
            </button> */}

            <div className="flex md:hidden lg:hidden flex-col items-center border border-zinc-600" style={{ backgroundColor: '#1e222d' }}>
              <h1 className="text-white font-bold font-mono">실시간 접속자</h1>
              <div className="flex flex-col h-full w-full justify-center items-center">
                <p className="text-white font-mono">connections : {this.state.connections}</p>
              </div>
            </div>
            {
              this.state.isSignalWork ? (
                <TradingChart
                  onRealtimeConnectionChange={(connections) => this.setState({ connections: `${connections}` })}

                  chartProperties={{
                    locale: getLocalLanguage(),
                    debug: false,
                    fullscreen: false,
                    symbol: "BTCUSDT",
                    interval: "15",
                    theme: "dark",
                    allow_symbol_change: true,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    autosize: true,
                    disabled_features: ["header_resolutions"],
                    isSignalWork: this.state.isSignalWork,
                    isTrial: true,

                  }} />
              ) : (
                <div style={{ width: '100%' }} className="bg-black">
                </div>
              )
            }

          </div>

          <div style={{ backgroundColor: "#303241" }} className="hidden sm:block md:block lg:block flex-col">
            {/* <div className="flex flex-col h-28" style={{ backgroundColor: '#1e222d', borderRadius: 3, marginBottom: 10 }}>
              <h1 className="text-white font-bold">Realtime Customers</h1>

            </div> */}

            {/* <button
              onClick={() => {
                mixpanel.track('Click_VimeoVideo_long_cut');
                window.open("https://vimeo.com/751532226", "_blank");
              }}
              className="text-white w-full bg-slate-600 rounded-md mb-1">
              WAVE.ALGO 소개 동영상 보기
            </button> */}

            {/* <div className="flex flex-col items-center border border-zinc-600 border-1 p-2" style={{ backgroundColor: '#1e222d', borderRadius: 3, marginBottom: 10 }}>
              <h1 className="text-white font-bold font-mono">실시간 접속자</h1>
              <div className="flex flex-col h-full w-full justify-center items-center">
                <p className="text-white font-mono">connections : {this.state.connections}</p>
              </div>
            </div> */}

            <TradingViewEmbed
              widgetType={widgetType.MARKET_OVERVIEW}
              widgetConfig={{
                colorTheme: "dark",
              }} />

            <div className="flex flex-col items-center border border-zinc-600 border-1 p-2" style={{ backgroundColor: '#1e222d', borderRadius: 3, marginBottom: 10 }}>

            </div>


          </div>

        </div>
      </div>
    )
  }
}